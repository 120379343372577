(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Initial App
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
const app = angular.module('app', [
  'ui.router',
  'pascalprecht.translate',
  'app.conf'
]);
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Config & Routing
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
app.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
  //===== Default Page =====//
  $urlRouterProvider.otherwise('/step1');
  $stateProvider.state('member_app',{
    abstract: true,
    templateUrl: 'views/layout.html'
  });
  $stateProvider.state('member_app.step1', {
    url: '/step1',
    templateUrl: 'views/step1.html'
  });
  $stateProvider.state('member_app.step2', {
    url: '/step2',
    templateUrl: 'views/step2.html'
  });
  $stateProvider.state('member_app.step2_params', {
    url: "/step2/{studioId:[0-9]{1,10}}/{studioName:.{1,255}}?:is_visitors",
    templateUrl: 'views/step2.html'
  });
  $stateProvider.state('member_app.step2_visitors', {
    url: "/visitors/{studioId:[0-9]{1,10}}/{studioName:.{1,255}}",
    templateUrl: 'views/step2.html'
  });
  $stateProvider.state('member_app.step2_in_studio', {
    url: "/in_studio/{studioId:[0-9]{1,10}}/{studioName:.{1,255}}",
    templateUrl: 'views/step2.html'
  });
  $stateProvider.state('member_app.step2_register', {
    url: "/register/{studioId:[0-9]{1,10}}/{studioName:.{1,255}}/{memberId:[0-9]{1,10}}/:tempPass",
    templateUrl: 'views/step2.html'
  });
  $stateProvider.state('member_app.step3', {
    url: '/step3/{studioId:[0-9]{1,10}}/{studioName:.{1,255}}',
    templateUrl: 'views/step3.html'
  });
  $stateProvider.state('member_app.step4', {
    url: '/step4/{type}/{studioId}',
    templateUrl: 'views/step4.html'
  });
  $stateProvider.state('member_app.error', {
    url: '/error',
    templateUrl: 'views/error.html'
  });
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Set Root Scope
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
//===== Run =====//
app.run(['$rootScope', '$state', '$stateParams', '$anchorScroll', 'myPageUrl', 'sfTopUrl', function($rootScope, $state, $stateParams, $anchorScroll, myPageUrl, sfTopUrl) {
  $rootScope.$on('$locationChangeSuccess',function(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $anchorScroll();
  });
  $rootScope.$state = $state;
  $rootScope.sf_top = sfTopUrl;
  $rootScope.my_page = myPageUrl;
  return $rootScope.$stateParams = $stateParams;
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Factories
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
app.factory('SfappFactory', ['$state', '$location', '$http', 'apiBaseUrl', 'http_config', function($state, $location, $http, apiBaseUrl, http_config) {
  var getStudioList = function() {
    var url = apiBaseUrl + '/studio/available';
    return $http.get(url, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  var getStudioPlanList = function(studio_id) {
    var url = apiBaseUrl + '/studio/' + studio_id + '/plan/available';
    return $http.get(url, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  let getFirstInvoice = function(studio_plan_id, start_date) {
    let url = apiBaseUrl + `/studio/plan/${studio_plan_id}/start_date/${start_date}`;
    return $http.get(url, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  let isDisplayed = function(studio_id) {
    let url = apiBaseUrl + '/studio/' + studio_id + '/is_displayed';
    return $http.get(url, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  var getAddress = function(zip) {
    var url = apiBaseUrl + '/member/zipcode/' + zip;
    return $http.get(url, http_config).then(function(response) {
      return response.data.result;
    });
  };
  var checkAddress = function(json) {
    var url = apiBaseUrl + '/member/address';
    return $http.post(url, json, http_config).then(function(response) {
      return response.data;
    });
  };
  var createMember = function(json) {
    var url = apiBaseUrl + '/member/new';
    return $http.post(url, json, http_config).then(function(response) {
      return response.data;
    });
  };
  var createTemporaryMember = function(json) {
    var url = apiBaseUrl + '/member/temp/new';
    return $http.post(url, json, http_config).then(function(response) {
      return response.data;
    });
  };
  // 地方をJsonで取得
  var getLocalNumberList = function() {
    var url = apiBaseUrl + '/studio/local';
    return $http.get(url, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  // 店舗ID, 店舗名を検証
  var studioExists = function(studio_id, studio_name) {
    var url = apiBaseUrl + '/studio/exists';
    var json = {id: studio_id, name: studio_name};
    return $http.post(url, json, http_config).then(function(response) {
      if(response.status != 200 || response.data.success != true) {
        return $location.path('/error');
      }
      return response.data.result;
    },
    function(data) {
      return $location.path('/error');
    });
  };
  // アンケートを取得
  var getQuestionary = function() {
    var url = apiBaseUrl + '/questionary';
    return $http.get(url, http_config).then(function(response) {
          if(response.status != 200 || response.data.success != true) {
            return $location.path('/error');
          }
          return response.data.result;
        },
        function(data) {
          return $location.path('/error');
        });
  };
  // 仮登録会員データを取得
  var getTempMember = function(member_id, password) {
    var url = apiBaseUrl + '/member/temp/get';
    var json = {memberId: member_id, password: password};
    return $http.post(url, json, http_config).then(function(response) {
      return response.data;
    });
  };
  var registerTempMember = function(json, pass) {
    var url = apiBaseUrl + '/member/temp/register' + '/' + pass;
    return $http.post(url, json, http_config).then(function(response) {
      return response.data;
    });
  };
  var deleteExpiredPassword = function() {
    var url = apiBaseUrl + '/member/temp/pass/delete';
    return $http.get(url, http_config).then(function(response) {
      return response.data;
    });
  };
  let uploadPicture = function(id, picture_id, key, file_data) {
    let memberUrl = apiBaseUrl + '/member/' + id + '/picture/' + picture_id + '/key/' + key;
    http_config.transformRequest = null;
    http_config.headers = {};
    http_config.headers['Content-type'] = undefined;
    return $http.post(memberUrl, file_data, http_config).then(
      function(response) {
        return response.data.result;
      }
    );
  };
  return {
    getStudioList: getStudioList,
    getStudioPlanList: getStudioPlanList,
    getFirstInvoice: getFirstInvoice,
    isDisplayed: isDisplayed,
    getAddress: getAddress,
    checkAddress: checkAddress,
    createMember: createMember,
    createTemporaryMember: createTemporaryMember,
    getLocalNumberList: getLocalNumberList,
    studioExists: studioExists,
    getQuestionary: getQuestionary,
    getTempMember: getTempMember,
    registerTempMember: registerTempMember,
    deleteExpiredPassword: deleteExpiredPassword,
    uploadPicture: uploadPicture,
  };
}]);

app.directive('convertAlphanumeric', convertAlphanumeric);
function convertAlphanumeric() {
  let directive = {
    restrict: 'A',
    require: 'ngModel',
    link: link
  }
  return directive;

  function link(scope, element, attrs) {
    let nameArray = attrs.ngModel.split('.');
    let reg = new RegExp((attrs.ngPattern.slice(1)).slice(0, -1));
    let minLength = attrs.ngMinlength || 0;
    let maxLength = attrs.ngMaxlength || 0;
    element.bind('blur', function () {
      let newValue = '';
      if(element[0].value) {
        newValue = String(element[0].value);
      }
      let value = String(newValue).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });

      if(minLength && value.length < minLength) {
        scope[nameArray[0]][nameArray[1]][nameArray[2]]  = undefined;
      } else {
        if (maxLength) {
          value = value.slice(0, maxLength);
        }

        if (reg.test(value)) {
          scope[nameArray[0]][nameArray[1]][nameArray[2]] = value;
        } else {
          scope[nameArray[0]][nameArray[1]][nameArray[2]] = undefined;
        }
      }
      scope.$apply(attrs.ngModel);
    });
  }
}

app.directive('ngFinishDisplay', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    scope: {
      notifyId: '<'
    },
    link: function (scope) {
      $timeout(function () {
        scope.$emit("keepFirstInvoice");
      });
    }
  };
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// File upload
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
app.directive('fileModel', ['$parse', function($parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      const model = $parse(attrs.fileModel)
      element.bind('change', function() {
        scope.$apply(() => {
          model.assign(scope, element[0].files[0]);
        })
      })
    },
  }
}]);

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Controllers
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
app.controller('MasterController', ['$scope', '$translate', function($scope, $translate) {
  $scope.init = () => {
    $scope.member = {};
    $scope.member.tmp = {};
    $scope.member.payment = {};
    $scope.member.cc = {};
    $scope.visitors = false;
    $scope.inStudio = false;
    $scope.uploadImg = {};
    $scope.translate = $translate.use();
  };
  $scope.init();
  $scope.$watch('uploadImg.img1', (newValue, oldValue, scope) => {
    if(!newValue) {
      scope.uploadImg.img1 = null;
      scope.uploadImg.imgUri1 = null;
      scope[`inputFile1Valid`] = null;
    } else {
      let reader = new FileReader();
      reader.onload = function(event) {
        $scope.$apply(function () {
          scope.uploadImg.imgUri1 = reader.result;
          scope[`inputFile1Valid`] = 'true';
        });
      };
      reader.readAsDataURL(newValue);
    }
  });
  $scope.$watch('uploadImg.img2', (newValue, oldValue, scope) => {
    if(!newValue) {
      scope.uploadImg.img2 = null;
      scope.uploadImg.imgUri2 = null;
      scope[`inputFile2Valid`] = null;
    } else {
      let reader = new FileReader();
      reader.onload = function(event) {
        $scope.$apply(function () {
          scope.uploadImg.imgUri2 = reader.result;
          scope[`inputFile2Valid`] = 'true';
        });
      };
      reader.readAsDataURL(newValue);
    }
  });
}]);

app.controller('StepOneController', ['$scope', '$state', '$http', 'SfappFactory', '$translate', function($scope, $state, $http, SfappFactory, $translate) {
  $scope.init();
  $translate.use('ja');
  $scope.$parent.$parent.translate = 'ja';
  $scope.studios = {};
  $scope.select_studio = null;
  $scope.locals = {};
  $scope.$parent.$parent.visitors = false;
  SfappFactory.getLocalNumberList().then(function(data) {
    $scope.locals = data;
  });
  SfappFactory.getStudioList().then(function(data) {
    $scope.studios = data;
    //console.log($scope.studios);
  });
  $scope.next = function() {
    var select_studio_string = $scope.select_studio;
    if(!$scope.member.tmp) $scope.member.tmp = {};
    $scope.member.tmp.studio = select_studio_string.split(',');
    $scope.member.studioId = Number($scope.member.tmp.studio[0]);
    return $state.go('member_app.step2');
  }
  $scope.studio_click = function(value, value2 = null) {
    if (value2 != null) {
      $scope.select_studio = value + ', ' + value2;
    } else {
      $scope.select_studio = value;
    }
  };
  $scope.studio_display = function(local, number) {
    return studio_display(local, number);
  }
}]);

app.controller('StepTwoController', ['$scope', '$state', '$http', '$sce', 'SfappFactory', 'ccUrl', 'shopId', '$translate', function($scope, $state, $http, $sce, SfappFactory, ccUrl, shopId, $translate) {
  $scope.ccUrl = ccUrl;
  $scope.birth_years = get_year_list();
  $scope.birth_months = get_month_list();
  $scope.birth_dates = get_date_list();
  $scope.isValidBirthDay = undefined;
  $scope.sexes = get_sex_list();
  $scope.nationalities = get_nationality_list();
  $scope.payment_methods = get_payment_method_list();
  $scope.cc_expire_months = get_month_list();
  $scope.cc_expire_years = get_cc_year_list();
  $scope.desired_start_date_list = get_desired_start_date_list();
  $scope.relationships = get_relationship_list();
  $scope.invalid_email = false;
  $scope.gmo_token_error = false;
  $scope.member.visitors = /^#!\/visitors\//.test(location.hash);
  $scope.$parent.$parent.visitors = $scope.member.visitors;
  $scope.member.isRegistration = /^#!\/register\//.test(location.hash);
  $scope.$parent.$parent.inStudio = /^#!\/in_studio\//.test(location.hash);
  $scope.member.isEntry = $scope.member.tmp.isEntry >= 0 ? $scope.member.tmp.isEntry : 1;
  $scope.isOKCcName = $scope.member.tmp?.isOKCcName;
  $scope.translate = $translate.use();

  $scope.changeLanguage = function(langKey) {
    $translate.use(langKey); // 選択された言語をアクティブにする
    $scope.translate = $translate.use();
    $scope.$parent.$parent.translate = $translate.use();
    $scope.questionarys = collect_options($scope.questionaryMaster, $scope.translate);
  };

  $scope.$watch('select_plan', (newValue, oldValue, scope) => {
    if(!newValue || newValue === '999999999,見学予約,0,0') {
      scope.member.desiredStartDate = null;
    }
  });
  if($scope.member.isRegistration) {
    let memberId = $state.params.memberId;
    let tempPass = $state.params.tempPass;
    if(Object.keys($scope.member.tmp).length === 0) {
      SfappFactory.getTempMember(memberId, tempPass).then(function (data) {
        if (!data.result) {
          return $state.go('member_app.step1');
        }
        $scope.member.tmp.tempPass = tempPass;
        angular.forEach(data.result, function (value, key) {
          $scope.member[key] = value;
        });
        $scope.select_sex = $scope.sexes.find((v) => {
          return v.id === data.result['sex']
        });
        let zipArray = data.result['zip']?.split('-');
        if(zipArray) {
          $scope.member.tmp.zip1 = zipArray[0];
          $scope.member.tmp.zip2 = zipArray[1];
        }
        let birthArray = data.result['birthday']?.split('-');
        if(birthArray) {
          $scope.member.tmp.birth_year = Number(birthArray[0]);
          $scope.member.tmp.birth_month = Number(birthArray[1]);
          $scope.member.tmp.birth_date = Number(birthArray[2]);
        }
        $scope.is_japanese = $scope.nationalities.find((v) => {
          return v.id === data.result['isJapanese']
        });
        let phoneArray = data.result['phone']?.split('-');
        if(phoneArray) {
          $scope.member.tmp.tel1 = phoneArray[0];
          $scope.member.tmp.tel2 = phoneArray[1];
          $scope.member.tmp.tel3 = phoneArray[2];
        }
      });
    }
  }
  $scope.member.tmp.firstInvoiceTotal = undefined;
  $scope.member.tmp.firstInvoice = undefined;
  // URLパラメータ有は、店舗ID+店舗名の完全一致で値をセット
  var studioId = $state.params.studioId;
  if (studioId !== undefined) {
    $scope.member.studioId = studioId;
    SfappFactory.studioExists(studioId, $state.params.studioName).then(function (exists) {
      if (!exists) {
        return $state.go('member_app.step1');
      }
      $scope.member.tmp.studio = [studioId, $state.params.studioName];
    })
  }

  if($scope.member.tmp.planString) {
    $scope.select_plan = $scope.member.tmp.planString;
  }
  if($scope.member.tmp.sex) {
    $scope.select_sex = $scope.member.tmp.sex;
  }
  if($scope.member.tmp.nationality) {
    $scope.is_japanese = $scope.member.tmp.nationality;
  }
  if($scope.member.payment.paymentMethod) {
    $scope.payment_method = $scope.member.payment.paymentMethod;
  }
  if($scope.member.tmp.relationship) {
    $scope.em_relationship = $scope.member.tmp.relationship;
  }
  SfappFactory.getStudioPlanList($scope.member.studioId).then(function(data) {
    $scope.plans = data;
    if($scope.plans.length == 0) {
      return $state.go('member_app.step1');
    }
  });
  SfappFactory.isDisplayed($scope.member.studioId).then(function(data) {
    $scope.isUnmanned = data.isUnmanned;
    if($scope.isUnmanned === 1 && !$scope.member.visitors) {
      $scope.payment_method = '1';
    }
    $scope.isDisplayedTourPlan = data.isDisplayedTourPlan;
    if($scope.isDisplayedTourPlan && $state.params.is_visitors === 'true') {
      $scope.member.isEntry = 0;
      $scope.payment_method = '0';
      $scope.select_plan = '999999999,見学予約,0,0';
    } else if(!$scope.isDisplayedTourPlan && $state.params.is_visitors === 'true') {
      $scope.visitorsError = true;
    }
    $scope.isDisplayedCcLink = data.isDisplayedCcLink;
    $scope.note = data.note?.replace(/\r?\n/g, '<br>');
  });
  SfappFactory.getQuestionary().then(function (data) {
    $scope.questionaryMaster = data;
    if($scope.member.tmp.questionarys !== undefined) {
      $scope.questionarys = $scope.member.tmp.questionarys;
    } else {
      $scope.questionarys = collect_options(data, $translate.use());
    }
  });
  $scope.searchZipCode = function(zip1, zip2) {
    if(zip1 !== undefined && zip2 !== undefined) {
      var zipCode = String(zip1) + String(zip2);
      $scope.member.zip = String(zip1) + '-' + String(zip2);
      if(zipCode && zipCode.length > 0) {
        SfappFactory.getAddress(zipCode).then(function(data) {
          //console.log(data);
          $scope.member.province = data.address1;
          $scope.member.city = data.address2;
          $scope.member.area = data.address3;
        });
      }
    }
  }
  $scope.next = function() {
    $scope.member.isSendKey = 1; //Need to visit studio
    $scope.member.isEnableWebLogin = 1; //False
    $scope.member.isReceiveAd = 1; //False
    if($scope.member.zip == undefined && $scope.member.tmp.zip1 !== undefined && $scope.member.tmp.zip2 !== undefined) {
      $scope.member.zip = String($scope.member.tmp.zip1) + '-' + String($scope.member.tmp.zip2);
    }
    if($scope.select_plan !== undefined) {
      var select_plan_string = $scope.select_plan;
      $scope.member.tmp.planString = $scope.select_plan;
      $scope.member.tmp.plan = select_plan_string.split(',');
      $scope.member.studioPlanId = Number($scope.member.tmp.plan[0]);

      if($scope.member.desiredStartDate) { // ≒ isUnmanned == 1
        SfappFactory.getFirstInvoice($scope.member.studioPlanId, $scope.member.desiredStartDate.id).then(v => {
            $scope.member.tmp.firstInvoice = v;
            $scope.member.tmp.firstInvoiceTotal = v.reduce((a, b) => {return a += b.amount}, 0);
            $scope.member.tmp.note = $sce.trustAsHtml($scope.note);
        });
        $scope.member.isKeyDelivered = 1;
      } else {
        delete $scope.member.isKeyDelivered;
      }
    }
    if($scope.member.tmp.birth_year !== undefined && $scope.member.tmp.birth_month !== undefined && $scope.member.tmp.birth_date !== undefined) {
      $scope.member.birthday = String($scope.member.tmp.birth_year) + '-' + String(("0" + String($scope.member.tmp.birth_month)).slice(-2)) + '-' + String(("0" + String($scope.member.tmp.birth_date)).slice(-2));
    }
    if($scope.select_sex !== undefined) {
      $scope.member.tmp.sex = $scope.select_sex;
      $scope.member.sex = $scope.select_sex.id;
    }
    if($scope.is_japanese !== undefined) {
      $scope.member.tmp.nationality = $scope.is_japanese;
      $scope.member.isJapanese = $scope.is_japanese.id;
    }
    if($scope.member.tmp.tel1 !== undefined && $scope.member.tmp.tel2 !== undefined && $scope.member.tmp.tel3 !== undefined) {
      $scope.member.phone = String($scope.member.tmp.tel1) + '-' + String($scope.member.tmp.tel2) + '-' + String($scope.member.tmp.tel3);
    }
    if($scope.payment_method !== undefined) {
      $scope.member.payment.paymentMethod = $scope.payment_method;
    }
    if($scope.em_relationship !== undefined) {
      $scope.member.tmp.relationship = $scope.em_relationship;
      $scope.member.emRelationship = $scope.em_relationship.id;
    }
    if($scope.member.tmp.emTel1 !== undefined && $scope.member.tmp.emTel2 !== undefined && $scope.member.tmp.emTel3 !== undefined) {
      $scope.member.emPhone = String($scope.member.tmp.emTel1) + '-' + String($scope.member.tmp.emTel2) + '-' + String($scope.member.tmp.emTel3);
    }
    if($scope.questionarys !== undefined) {
      $scope.member.tmp.questionarys = $scope.questionarys;
    }
    $scope.member.tmp.isEntry = $scope.member.isEntry;
    $scope.member.tmp.isOKCcName = $scope.isOKCcName;
    var checkString = {'checkString': $scope.member.email};
    SfappFactory.checkAddress(checkString).then(async function(data) {
      //console.log($scope.member);
      if($scope.member.email !== '' && $scope.member.email !== null && data.result === true) { // Email is duplicated:
        $scope.invalid_email = true;
      } else if($scope.member.email === '' || $scope.member.email === null || data.result === false) {
        $scope.invalid_email = false;
        if($scope.payment_method == 1) { // If CreditCard payment is selected:
          var gmo_token = await get_gmo_token(shopId, $scope.member.cc);
          //console.log(gmo_token);
          if(gmo_token === 'error') {
            $scope.gmo_token_error = true;
          } else {
            $scope.gmo_token_error = false;
            $scope.member.cc.token = gmo_token;
            return $state.go('member_app.step3', {studioId: $scope.member.tmp.studio[0],studioName: $scope.member.tmp.studio[1].trim()});
          }
        } else {
          return $state.go('member_app.step3', {studioId: $scope.member.tmp.studio[0],studioName: $scope.member.tmp.studio[1].trim()});
        } 
      }
    });
  }
  // web入会可能となる年齢に達しているかバリデート
  $scope.isEligibleAge = function (year, month, day) {
    // フォームサブミットボタンの有効無効を制御するフラグ
    $scope.isValidBirthDay = undefined;

    if (year == undefined || month == undefined|| day == undefined) {
      // 未選択がある場合は、判断しない
      return true;
    }
    let targetDay = new Date(year + "/" + month + "/" + day);
    let now = new Date();

    let eligibleYear = 18;
    if(now < new Date(now.getFullYear() + "/04/02")) {
      eligibleYear = 19;
    }
    $scope.isValidBirthDay = targetDay < new Date((now.getFullYear() - eligibleYear) + "/04/02");

    return $scope.isValidBirthDay;
  }
  // ラジオボタンの切り替え時
  $scope.toggleRadio = function(index, column_id) {
    let inputMemoFlg = getInputMemoFlg($scope.questionarys[index].selectColumns, column_id);

    // textareaの状態を設定する
    if (inputMemoFlg) {
      $scope.questionarys[index].isActiveTextArea = true;
    } else {
      $scope.questionarys[index].isActiveTextArea = false;
    }
    $scope.clearInputMemo(index);
  }
  // チェックボックスのマルチセレクト判定
  $scope.toggleCheck = function(index, column_id) {
    let isCheck = $scope.questionarys[index].check[column_id];
    let inputMemoFlg = getInputMemoFlg($scope.questionarys[index].selectColumns, column_id);

    // 選択項目を配列で管理
    if (isCheck === true) {
      if ($scope.questionarys[index].selectColumns.value === undefined) {
        $scope.questionarys[index].selectColumns.value = [];
      }
      $scope.questionarys[index].selectColumns.value.push(column_id);
      $scope.questionarys[index].selectColumns.value.sort(
          function(a,b){
            return (a < b ? -1 : 1);
          }
      );

      // チェックした項目のtextareaの状態を設定する
      if (inputMemoFlg) {
        $scope.questionarys[index].isActiveTextArea = true;
      }
    } else if (isCheck === false) {
      let deleteUnCheck = $scope.questionarys[index].selectColumns.value.filter(function( item ) {
        return item !== column_id;
      });
      $scope.questionarys[index].selectColumns.value = deleteUnCheck;

      // チェックを外した項目のtextareaをclear、状態を設定する。
      if (inputMemoFlg) {
        $scope.clearInputMemo(index);
        $scope.questionarys[index].isActiveTextArea = false;
      }
    }

    // エラー、バリデート制御
    if ($scope.questionarys[index].selectColumns.value.length === 0) {
      $scope.questionarys[index].selectColumns.hasCheck = undefined;
    } else {
      $scope.questionarys[index].selectColumns.hasCheck = true;
    }
  }
  // エラー、バリデート制御
  $scope.clearInputMemo = function(index) {
    $scope.questionarys[index].selectColumns.inputMemo = undefined;
  };
  $scope.$watch('select_plan', function(newValue, oldValue, scope){
    if(newValue === '999999999,見学予約,0,0') {
      scope.$parent.payment_method = '0';
    }
  });
  $scope.switchToEntry = function() {
    $scope.member.isEntry = 1;
    $scope.select_plan = undefined;
    if($scope.isUnmanned === 1) {
      $scope.payment_method = '1';
    }
  };
  $scope.switchToVisit = function() {
    $scope.member.isEntry = 0;
    $scope.select_plan = '999999999,見学予約,0,0';
    $scope.payment_method = '0';
  };
  $scope.checkPattern = function(value, regexString) {
    const regex = new RegExp(regexString);

    if(regex.test(value)) {
      $scope.isOKCcName = "OK";
    } else {
      $scope.isOKCcName = undefined;
    }
  };
}]);

app.controller('StepThreeController', ['$scope', '$state', '$http', 'SfappFactory', '$window', 'sfTopUrl', '$translate', function($scope, $state, $http, SfappFactory, $window, sfTopUrl, $translate) {
  $scope.result = {};
  $scope.translate = $translate.use();
  $scope.$parent.$parent.show_confirm = true;
  $scope.$parent.$parent.show_result = false;
  if($scope.member.desiredStartDate) {
    let desiredStartDateSplit = $scope.member.desiredStartDate.id.split('-'),
        modify = Number(desiredStartDateSplit[2]) > 12 ? 1 : 0;
    $scope.firstInvoiceDate = new Date(Number(desiredStartDateSplit[0]), Number(desiredStartDateSplit[1]) + modify, 1);
  }
  if(!$scope.member.visitors && !$scope.member.studioPlanId) {
    return $state.go('member_app.step1');
  }
  $scope.$on('keepFirstInvoice', function (event, data) {
    $scope.firstInvoiceElement = document.getElementById('studio_plan_info')?.innerHTML;
  });
  $scope.back = function() {
    return $state.go('member_app.step2');
  }
  $scope.save = function() {
    let json = $scope.member;
    if(json.desiredStartDate) {
      json.desiredStartDate = json.desiredStartDate.id + 'T00:00:00+09:00';
    }
    if(json.tmp.firstInvoice) {
      json.firstInvoice = {};
      json.firstInvoice.firstInvoiceDate = `${$scope.firstInvoiceDate.getFullYear()}-${('0' + ($scope.firstInvoiceDate.getMonth() + 1)).slice(-2)}-${('0' + $scope.firstInvoiceDate.getDate()).slice(-2)}T00:00:00+09:00`;
      json.firstInvoice.keyFee = json.tmp.firstInvoice[0]?.amount;
      json.firstInvoice.firstMonthFee = json.tmp.firstInvoice[1]?.amount;
      json.firstInvoice.secondMonthFee = json.tmp.firstInvoice[2]?.amount;
      json.firstInvoice.monthlyFee = json.tmp.firstInvoice[3]?.amount;
      if($scope.firstInvoiceElement) {
        json.firstInvoice.html = $scope.firstInvoiceElement;
      }
    }
    createQuestionaryResult($scope.member);
    json.status = $scope.member.studioPlanId === 999999999 ? 6 : 4;
    json.isWebRegister = $scope.$parent.$parent.inStudio ? 1 : 0;
    json.uploadImg = $scope.uploadImg.img1 || $scope.uploadImg.img2;
    json.translate = $scope.translate;
    delete json['tmp'];
    //console.log(json);
    $scope.$parent.$parent.show_confirm = false;
    $scope.$parent.$parent.show_result = true;
    $scope.$parent.$parent.show_process = true;
    $scope.$parent.$parent.show_success = false;
    $scope.$parent.$parent.show_failed = false;
    SfappFactory.createMember(json).then(function(data) {
      $scope.result = data.result;
      if($scope.result.tempPassword) {
        const options = {
          maxSizeMB: 0.8,
          maxWidthOrHeight: 800,
        };
        if($scope.uploadImg.img1) {
          imageCompression($scope.uploadImg.img1, options).then(blob => {
            const file = new File([blob], blob.name, {type: blob.type});
            let file_data = new FormData();
            file_data.append('file', file);
            SfappFactory.uploadPicture($scope.result.memberId, 1, $scope.result.tempPassword, file_data);
          });
        }
        if($scope.uploadImg.img2) {
          imageCompression($scope.uploadImg.img2, options).then(blob => {
            const file = new File([blob], blob.name, {type: blob.type});
            let file_data = new FormData();
            file_data.append('file', file);
            SfappFactory.uploadPicture($scope.result.memberId, 2, $scope.result.tempPassword, file_data);
          });
        }
      }
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_success = true;
      if($scope.result.createGmoMember && $scope.result.createGmoMember != 'success') {
        $scope.$parent.$parent.gmo_create_error = true;
      } else if($scope.result.createGmoMember && !$scope.result.saveCreditCard) {
        $scope.$parent.$parent.gmo_create_error = true;
      } else if($scope.result.saveCreditCard && $scope.result.saveCreditCard != 'success') {
        $scope.$parent.$parent.gmo_create_error = true;
      }
      if($scope.result.messageId) {
        $scope.$parent.$parent.email_sent = true;
      }
    }).catch(function(err) {
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_failed = true;
    }).finally(() => {
      return $state.go('member_app.step4', {type: 'new_membership',studioId: $scope.member.studioId});
    });
  };
  $scope.saveVisitors = function() {
    let json = $scope.member;
    if(json.desiredStartDate) {
      json.desiredStartDate = json.desiredStartDate.id + 'T00:00:00+09:00';
    }
    json.isWebRegister = 1;
    createQuestionaryResult($scope.member);
    json.status = 5;
    json.translate = $scope.translate;
    delete json['tmp'];
    //console.log(json);
    $scope.$parent.$parent.show_confirm = false;
    $scope.$parent.$parent.show_result = true;
    $scope.$parent.$parent.show_process = true;
    $scope.$parent.$parent.show_success = false;
    $scope.$parent.$parent.show_failed = false;
    SfappFactory.createTemporaryMember(json).then(function(data) {
      $scope.result = data.result;
      //console.log($scope.result);
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_success = true;
      if($scope.result.messageId) {
        $scope.$parent.$parent.email_sent = true;
      }
    }).catch(function(err) {
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_failed = true;
    }).finally(() => {
      return $state.go('member_app.step4', {type: 'temporary_registration', studioId: $scope.member.studioId});
    });
  };
  $scope.register = function() {
    if($scope.member.status === 6) {
      createQuestionaryResult($scope.member);
    }
    let json = JSON.parse(JSON.stringify($scope.member));
    let tempPass = String($scope.member.tmp.tempPass);
    json.status = 4;
    json.translate = $scope.translate;
    if(json.desiredStartDate) {
      json.desiredStartDate = json.desiredStartDate.id + 'T00:00:00+09:00';
    }
    if(json.tmp.firstInvoice) {
      json.firstInvoice = {};
      json.firstInvoice.firstInvoiceDate = `${$scope.firstInvoiceDate.getFullYear()}-${('0' + ($scope.firstInvoiceDate.getMonth() + 1)).slice(-2)}-${('0' + $scope.firstInvoiceDate.getDate()).slice(-2)}T00:00:00+09:00`;
      json.firstInvoice.keyFee = json.tmp.firstInvoice[0]?.amount;
      json.firstInvoice.firstMonthFee = json.tmp.firstInvoice[1]?.amount;
      json.firstInvoice.secondMonthFee = json.tmp.firstInvoice[2]?.amount;
      json.firstInvoice.monthlyFee = json.tmp.firstInvoice[3]?.amount;
      if($scope.firstInvoiceElement) {
        json.firstInvoice.html = $scope.firstInvoiceElement;
      }
    }
    delete json['tmp'];
    //console.log(json);
    $scope.$parent.$parent.show_confirm = false;
    $scope.$parent.$parent.show_result = true;
    $scope.$parent.$parent.show_process = true;
    $scope.$parent.$parent.show_success = false;
    $scope.$parent.$parent.show_failed = false;
    SfappFactory.registerTempMember(json, tempPass).then(function(data) {
      $scope.result = data.result;
      //console.log($scope.result);
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_success = true;
      if($scope.result.createGmoMember && $scope.result.createGmoMember != 'success') {
        $scope.$parent.$parent.gmo_create_error = true;
      } else if($scope.result.createGmoMember && !$scope.result.saveCreditCard) {
        $scope.$parent.$parent.gmo_create_error = true;
      } else if($scope.result.saveCreditCard && $scope.result.saveCreditCard != 'success') {
        $scope.$parent.$parent.gmo_create_error = true;
      }
      if($scope.result.messageId) {
        $scope.$parent.$parent.email_sent = true;
      }
    }).catch(function(err) {
      $scope.$parent.$parent.show_process = false;
      $scope.$parent.$parent.show_failed = true;
    }).finally(() => {
      SfappFactory.deleteExpiredPassword().then(function(data) {});
      return $state.go('member_app.step4', {type: 'main_registration', studioId: $scope.member.studioId});
    });
  };
  $scope.go_to_top = function() {
    $window.location.href = sfTopUrl;
  }
  // タグ挿入の要不要を判断する
  $scope.isIncludeTags = function () {
    if($scope.result === undefined) {
      return false;
    }
    if ($scope.result.createMember === "success" &&
        $scope.result.createMemberCode === "success" &&
        $scope.result.createPayment === "success" &&
        $scope.result.createTransaction === "success") {
      var memberCodes = $scope.result.memberCode.split('-');
      var studioId = memberCodes[0] * 1;
      if (studioId == 34) {
        return true;
      }
    }
    return false;
  }
  // idからアンケート選択肢の文字列を取得する
  $scope.getSelectColumnName = function (selectColumns) {
    let selectValue = selectColumns.value;
    let selectColumnName = '';

    $.each(selectColumns, function(idx, elm){
      if (idx == 'value' || idx == 'inputMemo') {
        return true;
      }
      if (Array.isArray(selectValue)) {
        $.each(selectValue, function(select_idx, select_val) {
          if (elm.id  == select_val && selectColumnName == '') {
            selectColumnName = elm.name;
          } else if (elm.id  == select_val) {
            selectColumnName += '、 ' + elm.name;
          }
        });
      } else {
        if(elm.id == selectValue) {
          selectColumnName = elm.name;
        }
      }
    });

    return selectColumnName;
  }
}]);

function get_year_list(start_year, end_year) {
  var now = new Date();
  var start_year = start_year || now.getFullYear() - 115;
  var end_year = end_year || now.getFullYear() - 15; //Member age restriction required.
  var year_list = [];
  for ( var i = start_year; i <= end_year; i++ ) {
    year_list.push(i);
  }
  year_list.sort(function(a, b){
    return (a < b ? 1 : -1);
  });
  return year_list;
}

function get_cc_year_list(start_year, end_year) {
  var now = new Date();
  var start_year = start_year || now.getFullYear();
  var end_year = end_year || now.getFullYear() + 10;
  var year_list = [];
  for ( var i = start_year; i <= end_year; i++ ) {
    year_list.push(String(i).slice( -2 ));
  }
  return year_list;
}

function get_month_list(start_month, end_month) {
  var start_month = start_month || 1;
  var end_month = end_month || 12;
  var month_list = [];
  for ( var i = start_month; i <= end_month; i++ ) {
    month_list.push(i);
  }
  return month_list;
}

function get_date_list(start_date, end_date) {
  var start_date = start_date || 1;
  var end_date = end_date || 31;
  var date_list = [];
  for ( var i = start_date; i <= end_date; i++ ) {
    date_list.push(i);
  }
  return date_list;
}

function get_sex_list() {
  var sex_list = [
    {
      id: 0,
      name: "male"
    }, {
      id: 1,
      name: "female"
    }
  ];
  return sex_list;
}

function get_nationality_list() {
  var nationality_list = [
    {
      id: 0,
      name: "japan"
    }, {
      id: 1,
      name: "otherThanJapan"
    }
  ];
  return nationality_list;
}

function get_payment_method_list() {
  var payment_method_list = [
    {
      id: 0,
      name: "口座振替"
    }, {
      id: 1,
      name: "クレジットカード"
    }
  ];
  return payment_method_list;
}

function get_relationship_list() {
  var relationship_list = [
    {
      id: 0,
      name: "wife"
    }, {
      id: 1,
      name: "husband"
    }, {
      id: 2,
      name: "father"
    }, {
      id: 3,
      name: "mother"
    }, {
      id: 4,
      name: "child"
    }, {
      id: 5,
      name: "sibling"
    }, {
      id: 6,
      name: "other"
    }
  ];
  return relationship_list;
}

function get_gmo_token(shop_id, cc_object) {
  return new Promise(resolve => {
    var hodername = String(cc_object.name);
    var cardno = String(cc_object.number);
    var expire = String(cc_object.expire_year) + ('0' + String(cc_object.expire_month)).slice(-2);
    var securitycode = String(cc_object.security);
    Multipayment.init(shop_id);
    Multipayment.getToken({
      holdername: hodername,
      cardno: cardno,
      expire: expire,
      securitycode: securitycode
    }, function(res) {
      //console.log(res);
      if(res.resultCode == '000' && res.tokenObject.token) {
        resolve(res.tokenObject.token);
      } else {
        resolve('error');
      }
    });
  });
}
/**
 * 店舗アコーディオン作成時に要素追加の要不要を判断する
 * @param local 地方オブジェクト
 * @param number ローカルナンバー
 * @returns {boolean} 地方オブジェクトとローカルナンバーが一致すればtrue
 */
function studio_display(local, number) {
  var isDisplay = false;

  if (Array.isArray(number)) {
    number.forEach(function (value) {
      if (local.localNumber == value.localNumber) {
        isDisplay = true;
        return true;
      }
    })
  } else {
    if (local.localNumber == number) {
      isDisplay = true;
    }
  }

  return isDisplay;
}

/**
 * questionaryGroupId毎にselectColumnをまとめる
 *
 * @param {array} data
 * @param {string} translate
 * @returns questionarys
 */
function collect_options (data, translate = 'ja') {
  var questionarys = {};
  var questionary, selectColumns, selectColumn, questionaryGroupId, dataCount;

  init_data(true);
  $.each(data, function (idx, val) {
    if (questionaryGroupId === 0) {
      // 初回
      set_questionary(val);
    } else if (questionaryGroupId !== parseInt(val['questionaryGroupId'])) {
      // まとまる対象ではない
      set_questionarys();
      dataCount++;
      init_data();
      set_questionary(val);
    } else if (questionaryGroupId === parseInt(val['questionaryGroupId'])) {
      // まとめる対象である
      set_select_columns(val);
    }
  });
  set_questionarys();

  return questionarys;

  // 初期化
  function init_data(is_first = false) {
    if (is_first) {
      questionaryGroupId = 0;
      dataCount = 1;
    }
    questionary = {};
    selectColumns = {};
  }
  // データセット
  function set_questionary(val) {
    set_questionary_data(val, translate);
    set_select_columns(val);
  }
  function set_select_columns(val) {
    set_select_column_data(val, translate);
    set_select_columns_data(val);
  }
  function set_questionarys() {
    questionary['scopeName'] = dataCount;
    questionary['selectColumns'] = selectColumns;
    questionarys[questionaryGroupId] = questionary;
  }
  function set_questionary_data(val, translate) {
    questionary =  {
      questionaryContent: val[`questionaryContent${capitalize(translate)}`] || val['questionaryContent'],
      questionaryGroupId: val['questionaryGroupId'],
      freeFlg: val['freeFlg'],
      isMultiSelect: val['isMultiSelect'],
    };
  }
  function set_select_column_data(val, translate) {
    selectColumn =  {
      id: val['id'],
      inputMemoFlg: val['inputMemoFlg'],
      inputMemoContent: val['inputMemoContent'],
      name: val[`selectColumn${capitalize(translate)}`] || val['selectColumn']
    };
  }
  function set_select_columns_data(val) {
    selectColumns[Object.keys(selectColumns).length] = selectColumn;
    questionaryGroupId = val['questionaryGroupId'];
  }
  function capitalize(str) {
    if(str === 'ja') {
      return '';
    }
    if (typeof str !== 'string' || !str) return str;
    if(str.split('-').length === 2) {
      const splitStr = str.split('-');
      return splitStr[0].charAt(0).toUpperCase() + splitStr[0].slice(1).toLowerCase() + splitStr[1];
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}

/**
 * アンケート結果を作成してmemberに詰める
 *
 * @param scopeMember
 */
function createQuestionaryResult(scopeMember) {
  let questionarys, questionaryResults;
  questionarys = scopeMember.tmp.questionarys;
  questionaryResults = [];

  $.each(questionarys, function(index, questionary){
    let questionaryResult;
    let questionaryMasterid =  questionary.selectColumns.value;
    if (Array.isArray(questionaryMasterid)) {
      // 複数選択処理
      $.each(questionaryMasterid, function(index, id){
        questionaryResult = getQuestionaryResult(scopeMember, questionary, id);
        questionaryResults.push(questionaryResult);
      });
    } else {
      questionaryResult = getQuestionaryResult(scopeMember, questionary, questionaryMasterid);
      questionaryResults.push(questionaryResult);
    }
  });
  scopeMember.questionaryResult = questionaryResults;
}

/**
 * questionaryResult1データ分設定する
 *
 * @param scopeMember
 * @param questionary
 * @param id
 * @returns {{studioId: number, studioPlanId: number, inputMemoResult: null, questionaryMasterId: *, memberId: null}}
 */
function getQuestionaryResult(scopeMember, questionary, id){
  let inputMemo = null;
  let columns = questionary.selectColumns;
  let inputMemoFlg = getInputMemoFlg(columns, id);

  if (questionary.selectColumns.inputMemo != undefined && (questionary.freeFlg || inputMemoFlg)) {
    inputMemo = questionary.selectColumns.inputMemo;
  }

  return {
    questionaryMasterId: id,
    studioId: scopeMember.studioId,
    studioPlanId: scopeMember.studioPlanId,
    memberId: null,
    inputMemoResult: inputMemo,
  };
}

/**
 * inputMemoFlgを取得する
 *
 * @param {array} columns
 * @param {int} id
 * @returns {boolean}
 */
function getInputMemoFlg(columns, id) {
  let inputMemoFlg = false;

  $.each(columns, function(index, element) {
    if (isNaN(index)) {
      return true;
    }
    if (element.id == id && element.inputMemoFlg == 1) {
      inputMemoFlg = true;
    }
  });

  return inputMemoFlg;
}

function get_desired_start_date_list() {
  const now = new Date(),
        margin = 3,
        dateList = [1, 5, 10, 16, 20, 25],
        loopLimit = 100;
  let currentDateIndex = now.getDate() + margin;

  return Array(dateList.length).fill().reduce(data => {
    let target;
    while(currentDateIndex < loopLimit) {
      target = new Date(now.getFullYear(), now.getMonth(), currentDateIndex);
      if(dateList.indexOf(target.getDate()) === -1) {
        currentDateIndex++;
        continue;
      }
      let year = target.getFullYear(),
          month = ('0' + (target.getMonth() + 1)).slice(-2),
          day = ('0' + target.getDate()).slice(-2);
      data.push({
        id: `${year}-${month}-${day}`,
        name: `${year}年${month}月${day}日`,
      });
      currentDateIndex++;
      break;
    }

    return data;
  }, []);
}

},{}],2:[function(require,module,exports){
angular.module('app').config(['$translateProvider', function ($translateProvider) {
  $translateProvider.translations('en', {
    selectAGymBranch: "Select a Gym Branch",
    enterYourDetails: "Enter your Details",
    confirm: "Confirm",
    complete: "Complete",
    membershipRegistration: "Membership Registration / Visit Reservation",
    webMembership: "Membership Registration",
    selectMembershipType: "Select a Membership Type",
    selectMembershipTypeMsg: "Please select a membership type.",
    membershipCategory: "Membership type",
    required: "Required",
    membershipCategoryRequired: "Membership type is required.",
    basicInfoInput: "Your Details",
    basicInfoInputMsg: "Please enter your details.",
    fullName: "Full Name",
    lastNameRequired: "Last name is required.",
    firstNameRequired: "First name is required.",
    lastNameKanaRequired: "Last name (Kana) is required.",
    firstNameKanaRequired: "First name (Kana) is required.",
    lastNameLabel: "Last name",
    firstNameLabel: "First name",
    lastNameKanaLabel: "Last name (Kana)",
    firstNameKanaLabel: "First name (Kana)",
    gender: "Gender",
    genderRequired: "Gender is required.",
    male: "Male",
    female: "Female",
    address: "Address",
    postcodeRequired: "Postcode is required.",
    addressRequired: "Address is required.",
    autofill: "Autofill",
    postcodeSearch: "Postcode search",
    prefecture: "Prefecture",
    cityTownArea: "City/town area",
    addressLine: "Chome address",
    buildingNameRoomNumber: "Building name and room number",
    dateOfBirth: "Date of Birth",
    dateOfBirthRequired: "Date of Birth is required.",
    year: "Year",
    month: "Month",
    day: "Day",
    nationality: "Nationality",
    nationalityRequired: "Nationality is required.",
    japan: "Japan",
    otherThanJapan: "Other than Japan",
    emailAddress: "Email Address",
    emailAddressRequired: "Email Address is required.",
    emailAddressValid: "Please enter an email address that you can   access.",
    emailAddressReenter: "Email Address（Re-enter）",
    emailAddressReenterRequired: "Email Address（Re-enter） is required.",
    phoneNumber: "Phone Number",
    phoneNumberRequired: "Phone Number is required.",
    selectPaymentMethod: "Payment Method",
    paymentMethod: "Payment Method",
    selectPaymentMethodMsg: "Please select a payment method.If you wish to pay by credit card, please enter your credit card information.",
    paymentMethodRequired: "Payment method is required.",
    directDebit: "Direct debit",
    creditCard: "Credit card",
    emergencyContact: "Emergency Contact",
    emergencyContactMsg: "This is the emergency contact information in case you are injured at the facility. Please provide a contact other than yourself.",
    relationship: "Relationship",
    relationshipRequired: "Relationship is required.",
    wife: "Wife",
    husband: "Husband",
    father: "Father",
    mother: "Mother",
    child: "Child",
    sibling: "Brother / Sister",
    other: "Other",
    termsOfUse: "Terms of Use",
    termsOfUseMsg: 'For your comfort and convenience,We ask that you agree to our Terms of Use（Membership Terms, Rules of Use, and Privacy Policy） when you join our membership.Please check the "I agree" box.',
    membershipTerms: "Membership Terms",
    membershipTermsRequired: "You must agree to the Membership Terms to register.",
    agreeToMembershipTerms: "I agree to the Membership Agreement.",
    rulesOfUse: "Rules of Use",
    rulesOfUseRequired: "You must agree to the Rules of Use to register.",
    agreeToRulesOfUse: "I agree to the Rules of Use.",
    privacyPolicy: "Privacy Policy",
    privacyPolicyRequired: "You must agree to the Privacy Policy to register.",
    agreeToPrivacyPolicy: "I agree to the Privacy Policy.",
    questionnaire: "Questionnaire",
    questionnaireMsg: "Please take the questionnaire.",
    howDidYouHearAboutUs: "How did you hear about us?",
    questionnaireRequired: "Questionnaire is required.",
    handDeliveredFlyer: "Hand-delivered flyer/leaflet",
    postboxFlyer: "Postbox flyer/leaflet",
    newspaperFlyer: "Newspaper flyer/leaflet",
    exteriorOfTheGym: "Exterior of the gym",
    signboardAtRailwayStation: "Signboard at railway station",
    signboardOnStreet: "Signboard on street",
    facebook: "Facebook",
    instagram: "Instagram",
    searchEngineWebsite: "Search engine / Website",
    referral: "Referral",
    purposeOfGoingToGym: "What is your purpose of going to the gym?",
    healthMaintenance: "Health maintenance",
    stressRelief: "Stress relief",
    muscleStrengthening: "Muscle strengthening",
    losingWeight: "Losing weight",
    hobby: "Hobby",
    whatIsImportantWhenChoosingGym: "What is most important to you when choosing a gym?",
    location: "Location",
    expenses: "Expenses",
    trainingMachines: "Training machines",
    showerRoomRestroom: "Shower room / Restroom",
    businessHours: "Business hours",
    personalTraining: "Personal training",
    staffServices: "Services of staff",
    hygiene: "Hygiene",
    haveYouBeenToAnotherFitnessGym: "Have you ever been to another fitness gym?",
    no: "No",
    yes: "Yes",
    inputConfirmation: "Confirm",
    modify: "Back",
    submitForm: "Submit the form",
    visitReservation: "Visit Reservation",
    registrationAccepted: "Your registration has been accepted.",
    emailConfirmationMsg: "We have sent you an email regarding your registration. Please check.",
    backToTop: "Home",
    kanji: 'Name',
    kana: 'Name (kana)',
    pleaseSelect: 'Please select',
    PleaseComeToTheBranch: 'Please come to the branch office during the staff reception hours (varies by branch) with your cash card.',
    branch: 'Branch',
    cardNumber: 'Card number',
    cardNumberIsRequired: 'Card number is required.',
    nameOnCard: 'Name on card',
    nameOnCardIsRequired: 'Name on card is required.',
    expiryDate: 'Expiry date',
    expiryDateIsRequired: 'Expiry date is required.',
    securityCode: 'Security code',
    pleaseEnterYourInformation: 'Please enter your information.',
    termsOfVisit: 'Terms of Visit',
    emailAlreadyUsed: 'This Email-address is already in use. If you want to re-register for a membership, please come directly to the branch during the reception hours.',
  });

  $translateProvider.translations('zh-CN', {
    selectAGymBranch: "门店选择",
    enterYourDetails: "输入基本信息",
    confirm: "确认输入内容",
    complete: "完成申请",
    membershipRegistration: "申请会员/预约参观",
    webMembership: "WEB入会",
    selectMembershipType: "会员种类选择",
    selectMembershipTypeMsg: "请选择您希望的会员种类",
    membershipCategory: "会员种类",
    required: "必填",
    membershipCategoryRequired: "会员种类是必填项目。请选择。",
    basicInfoInput: "填写基本信息",
    basicInfoInputMsg: "请填写您的基本信息。",
    fullName: "姓名",
    lastNameRequired: "姓是必填项目。请填写。",
    firstNameRequired: "名是必填项目。请填写。",
    lastNameKanaRequired: "注音假名（姓）是必填项目。请填写。",
    firstNameKanaRequired: "注音假名（名）是必填项目。请填写。",
    lastNameLabel: "姓",
    firstNameLabel: "名",
    lastNameKanaLabel: "注音假名（姓）",
    firstNameKanaLabel: "注音假名（名）",
    gender: "性別",
    genderRequired: "性别是必填项目。请填写。",
    male: "男性",
    female: "女性",
    address: "住址",
    postcodeRequired: "邮政编码为必填项目。请填写。",
    addressRequired: "地址为必填项目。请填写。",
    autofill: "地址自动填写",
    postcodeSearch: "查找邮政编码",
    prefecture: "都道府县",
    cityTownArea: "市区町村以下",
    addressLine: "番地",
    buildingNameRoomNumber: "大楼/公寓名称和房间号",
    dateOfBirth: "出生日期",
    dateOfBirthRequired: "出生日期为必填项目。 请填写。",
    year: "公元",
    month: "月",
    day: "日",
    nationality: "国籍",
    nationalityRequired: "国籍为必填项目。请填写。",
    japan: "日本",
    otherThanJapan: "日本境外",
    emailAddress: "电子邮件地址",
    emailAddressRequired: "电子邮件地址为必填项目。请正确填写。",
    emailAddressValid: "※请填写一个可以正常接收邮件的邮箱地址。",
    emailAddressReenter: "确认电子邮件地址",
    emailAddressReenterRequired: "确认电子邮件地址为必填项目。请正确填写。",
    phoneNumber: "电话号码",
    phoneNumberRequired: "电话号码为必填项目。请填写。",
    selectPaymentMethod: "选择付款方式",
    paymentMethod: "付款方式",
    selectPaymentMethodMsg: "请选择付款方式。如果您希望使用信用卡支付，请填写您的信用卡详细信息。",
    paymentMethodRequired: "必须指定付款方式",
    directDebit: "银行账户扣款",
    creditCard: "信用卡",
    emergencyContact: "填写紧急联系人",
    emergencyContactMsg: "在设施内万一受伤或发生其他事故时的紧急联系人。请填写您本人以外的联系人。",
    relationship: "与您的关系",
    relationshipRequired: "请注明紧急联系人与您的关系。",
    wife: "妻子",
    husband: "丈夫",
    father: "父亲",
    mother: "母亲",
    child: "孩子",
    sibling: "兄弟姐妹",
    other: "其他",
    termsOfUse: "确认使用条款",
    termsOfUseMsg: '为了您的舒适和方便、我们要求您在入会时同意各种使用条款和条件（会员条款、使用规则和个人信息保护条款）。请勾选 "我同意 "按钮。',
    membershipTerms: "会员协议",
    membershipTermsRequired: "注册时需接受会员协议。",
    agreeToMembershipTerms: "我同意会员条款。",
    rulesOfUse: "使用规则",
    rulesOfUseRequired: "注册时需同意使用规则。",
    agreeToRulesOfUse: "我同意使用规则。",
    privacyPolicy: "个人信息保护条款",
    privacyPolicyRequired: "注册需要同意个人信息保护条款。",
    agreeToPrivacyPolicy: "我同意个人信息保护条款。",
    questionnaire: "入会调查问卷",
    questionnaireMsg: "请填写调查问卷。",
    howDidYouHearAboutUs: "您是通过何种渠道知晓到我们健身房的？",
    questionnaireRequired: "请填写调查问卷。",
    handDeliveredFlyer: "街边传单",
    postboxFlyer: "信箱传单",
    newspaperFlyer: "报纸内附的传单",
    exteriorOfTheGym: "店铺外观",
    signboardAtRailwayStation: "车站广告牌",
    signboardOnStreet: "道路广告牌",
    facebook: "Facebook",
    instagram: "Instagram",
    searchEngineWebsite: "在网上寻找健身房",
    referral: "朋友介绍",
    purposeOfGoingToGym: "您使用健身中心的目的是什么？",
    healthMaintenance: "维持健康",
    stressRelief: "减压",
    muscleStrengthening: "增肌",
    losingWeight: "减重",
    hobby: "兴趣爱好",
    whatIsImportantWhenChoosingGym: "您在选择健身房时，什么对您来说最重要？",
    location: "位置",
    expenses: "价格",
    trainingMachines: "健身器械",
    showerRoomRestroom: "浴室和卫生间",
    businessHours: "营业时间",
    personalTraining: "私教课程",
    staffServices: "工作人员的服务品质",
    hygiene: "清洁卫生方面",
    haveYouBeenToAnotherFitnessGym: "您是否曾使用过其他健身房？",
    no: "否",
    yes: "是",
    inputConfirmation: "确认输入内容",
    modify: "修正",
    submitForm: "完成申请",
    visitReservation: "预约参观",
    registrationAccepted: "您的入会申请已经得到受理",
    emailConfirmationMsg: "请注意，有关您的入会的邮件已发送到您所注册的电子邮件地址。",
    backToTop: "返回顶部",
    kanji: "漢字",
    kana: "注音假名",
    pleaseSelect: "请选择。",
    PleaseComeToTheBranch: "※银行账户扣款需在工作人员接待时间内来门店办理。",
    branch: "签约门店",
    cardNumber: "信用卡号",
    cardNumberIsRequired: "请输入信用卡号",
    nameOnCard: "信用卡名义人",
    nameOnCardIsRequired: "请输入信用卡名义人",
    expiryDate: "信用卡有效期",
    expiryDateIsRequired: "请输入信用卡有效期",
    securityCode: "信用卡安全码",
    pleaseEnterYourInformation: "请填写您的基本信息。",
    termsOfVisit: "确认个人信息保护条款",
    emailAlreadyUsed: '此邮箱地址已被使用。若您希望重新入会，请在接待时间内直接访问健身房门店。',
  });

  $translateProvider.translations('ja', {
    selectAGymBranch: "店舗選択",
    enterYourDetails: "基本情報等の入力",
    confirm: "入力内容確認",
    complete: "お申し込み完了",
    membershipRegistration: "お申し込み / 見学予約",
    webMembership: "WEB入会",
    selectMembershipType: "コース選択",
    selectMembershipTypeMsg: "コースを選択してください。",
    membershipCategory: "会員区分",
    required: "必須",
    membershipCategoryRequired: "会員区分は必須項目です。ご選択ください。",
    basicInfoInput: "基本情報の入力",
    basicInfoInputMsg: "会員となるお客様の情報を入力してください。",
    fullName: "氏名",
    lastNameRequired: "姓は必須項目です。ご入力ください。",
    firstNameRequired: "名は必須項目です。ご入力ください。",
    lastNameKanaRequired: "ふりがな（姓）は必須項目です。ご入力ください。",
    firstNameKanaRequired: "ふりがな（名）は必須項目です。ご入力ください。",
    lastNameLabel: "姓",
    firstNameLabel: "名",
    lastNameKanaLabel: "ふりがな（姓）",
    firstNameKanaLabel: "ふりがな（名）",
    gender: "性別",
    genderRequired: "性別は必須項目です。ご入力ください。",
    male: "男性",
    female: "女性",
    address: "住所",
    postcodeRequired: "郵便番号(半角数字)は必須項目です。ご入力ください。",
    addressRequired: "住所は必須項目です。ご入力ください。",
    autofill: "住所自動入力",
    postcodeSearch: "郵便番号を調べる",
    prefecture: "都道府県",
    cityTownArea: "市区町村以下",
    addressLine: "番地",
    buildingNameRoomNumber: "ビル／マンション名・部屋番号",
    dateOfBirth: "生年月日",
    dateOfBirthRequired: "生年月日は必須項目です。ご入力ください。",
    year: "西暦",
    month: "月",
    day: "日",
    nationality: "国籍",
    nationalityRequired: "国籍は、必ず指定してください",
    japan: "日本",
    otherThanJapan: "日本以外",
    emailAddress: "メールアドレス",
    emailAddressRequired: "メールアドレスは必須項目です。正確にご記入ください。",
    emailAddressValid: "※受信可能なアドレスをご登録ください",
    emailAddressReenter: "メールアドレス確認用",
    emailAddressReenterRequired: "メールアドレス確認用は必須項目です。正確にご記入ください。",
    phoneNumber: "電話番号",
    phoneNumberRequired: "電話番号は必須項目です。ご記入ください。",
    selectPaymentMethod: "お支払い方法の選択",
    paymentMethod: "お支払い方法",
    selectPaymentMethodMsg: "お支払い方法をご選択ください。クレジット決済をご希望の方はクレジットカード情報をご入力ください。",
    paymentMethodRequired: "お支払い方法は、必ず指定してください",
    directDebit: "口座振替",
    creditCard: "クレジットカード",
    emergencyContact: "緊急連絡先の入力",
    emergencyContactMsg: "施設で万が一ケガなどをしてしまった場合の緊急連絡先です。ご本人様以外の連絡先をご入力ください。",
    relationship: "続柄",
    relationshipRequired: "続柄は、必ず指定してください",
    wife: "妻",
    husband: "夫",
    father: "父",
    mother: "母",
    child: "子",
    sibling: "兄弟",
    other: "その他",
    termsOfUse: "利用規約のご確認",
    termsOfUseMsg: "皆様に快適にご利用頂くため、ご入会にあたり各種利用規約（会員規約・利用ルール・個人情報保護規程）への同意を頂いております。「同意する」ボタンにチェックをお入れください。",
    membershipTerms: "会員規約",
    membershipTermsRequired: "登録には会員規約の同意が必要です。",
    agreeToMembershipTerms: "会員規約に同意します。",
    rulesOfUse: "利用ルール",
    rulesOfUseRequired: "登録には利用ルールへの同意が必要です。",
    agreeToRulesOfUse: "利用ルールに同意します。",
    privacyPolicy: "個人情報保護規定",
    privacyPolicyRequired: "登録には個人情報保護規定への同意が必要です。",
    agreeToPrivacyPolicy: "個人情報保護規定に同意します。",
    questionnaire: "ご入会アンケート",
    questionnaireMsg: "アンケートにご協力ください。",
    howDidYouHearAboutUs: "当ジムを知ったきっかけは何ですか",
    questionnaireRequired: "アンケートは、必ず回答してください。",
    handDeliveredFlyer: "手渡しチラシ",
    postboxFlyer: "ポストチラシ",
    newspaperFlyer: "新聞チラシ",
    exteriorOfTheGym: "外観",
    signboardAtRailwayStation: "駅看板",
    signboardOnStreet: "道路看板",
    facebook: "フェイスブック",
    instagram: "インスタグラム",
    searchEngineWebsite: "ネットでジムを探していた",
    referral: "知人紹介",
    purposeOfGoingToGym: "フィットネスジムを利用する目的は何ですか。",
    healthMaintenance: "健康維持",
    stressRelief: "ストレス解消",
    muscleStrengthening: "筋肉増強",
    losingWeight: "ダイエット",
    hobby: "趣味",
    whatIsImportantWhenChoosingGym: "フィットネスジムを選ぶ際に最も重視していることは何ですか",
    location: "立地",
    expenses: "価格",
    trainingMachines: "マシン",
    showerRoomRestroom: "シャワー・トイレ",
    businessHours: "営業時間",
    personalTraining: "パーソナルトレーニング",
    staffServices: "スタッフの対応",
    hygiene: "衛生面",
    haveYouBeenToAnotherFitnessGym: "他のフィットネスジムに通ったことはありますか",
    no: "ない",
    yes: "ある",
    inputConfirmation: "入力内容の確認",
    modify: "修正する",
    submitForm: "申し込みを完了する",
    visitReservation: "見学予約",
    registrationAccepted: "お申し込みを受け付けました",
    emailConfirmationMsg: "ご登録いただいたメールアドレス宛にご入会に関するメールを送付いたしましたので、ご確認ください。",
    backToTop: "トップに戻る",
    kanji: '漢字',
    kana: 'ふりがな',
    pleaseSelect: '選択してください',
    PleaseComeToTheBranch: '※口座振替の場合はスタッフ受付時間のご来店が必要です。',
    branch: '入会希望店舗',
    cardNumber: 'クレジットカード番号',
    cardNumberIsRequired: 'クレジットカード番号は、必ず指定してください',
    nameOnCard: 'クレジットカード名義',
    nameOnCardIsRequired: 'クレジットカード名義は、必ず指定してください',
    expiryDate: 'クレジットカード有効期限',
    expiryDateIsRequired: 'クレジットカード有効期限は、必ず指定してください',
    securityCode: 'クレジットカードセキュリティコード',
    pleaseEnterYourInformation: 'ご来館者様の情報を入力してください。',
    termsOfVisit: '個人情報保護規定のご確認',
    emailAlreadyUsed: 'このアドレスは既に登録済みです。再入会の方は直接店舗へお越しください。',
  });

  $translateProvider.preferredLanguage('ja'); // デフォルトの言語を日本語に設定
}]);

},{}],3:[function(require,module,exports){
var ua = window.navigator.userAgent.toLowerCase();
var ver = window.navigator.appVersion.toLowerCase();
 
var isMSIE = (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1);
var isIE6 = isMSIE && (ver.indexOf('msie 6.') > -1);
var isIE7 = isMSIE && (ver.indexOf('msie 7.') > -1);
var isIE8 = isMSIE && (ver.indexOf('msie 8.') > -1);
var isIE9 = isMSIE && (ver.indexOf('msie 9.') > -1);
var isIE10 = isMSIE && (ver.indexOf('msie 10.') > -1);
var isIE11 = (ua.indexOf('trident/7') > -1);
var isIE = isMSIE || isIE11;
var isEdge = (ua.indexOf('edge') > -1);
 
var isChrome = (ua.indexOf('chrome') > -1) && (ua.indexOf('edge') == -1);
var isFirefox = (ua.indexOf('firefox') > -1);
var isSafari = (ua.indexOf('safari') > -1) && (ua.indexOf('chrome') == -1);
var isOpera = (ua.indexOf('opera') > -1);
 
var isiPhone = (ua.indexOf('iphone') > -1);
var isiPad = (ua.indexOf('ipad') > -1);
var isAndroid = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') > -1);
var isAndroidTablet = (ua.indexOf('android') > -1) && (ua.indexOf('mobile') == -1);
 
// For browser support
if(isIE || isEdge || isOpera) {
  setTimeout("redirect_now()", 0);
  function redirect_now(){
    location.href='/redirect.html';
  }
}

// For maintenance
//setTimeout("maintenance_now()", 0);
function maintenance_now(){
  location.href='/maintenance.html';
}
},{}],4:[function(require,module,exports){
'use strict';

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++
// step4 Controller
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++

angular
.module('app')
.controller('StepFourController', StepFourController);

StepFourController.$inject = ['$scope', '$state', '$http', 'SfappFactory', '$window', '$stateParams', 'sfTopUrl', '$translate'];
function StepFourController($scope, $state, $http, SfappFactory, $window, $stateParams, sfTopUrl, $translate) {
  if(!$scope.$parent.show_failed && !$scope.$parent.show_success) {
    return $state.go('member_app.step1');
  }
  $scope.translate = $translate.use();
  $scope.studioTag = '';
  let type =  $stateParams.type;
  let studioId = $stateParams.studioId;

  if(type === 'new_membership') {
    $scope.studioTag = `views/partials/studioTags/studioTagParts_${studioId}.html`;
  }
  $scope.lineCVTag = 'views/partials/studioTags/line_conversion.html';
  $scope.sf3478Tag = 'views/partials/studioTags/tag_sf_3_478.html';

  $scope.go_to_top = function() {
    $window.location.href = sfTopUrl;
  }
}

},{}],5:[function(require,module,exports){
$(function(){
  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"]').click(function() {
    // スクロールの速度
    var speed = 400; // ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});

// アコーディオンリスト
$(function() {
  $(".content_accordion").hide();
  $(document).on('click', ".content_trigger", function() {
    $(this).next(".content_accordion:not(:animated)").slideToggle(300);
    $(this).toggleClass('current');
  });
});

$(function() {
  $("ul.tokyo_sub").hide();
  $("#kantou_btn").hover(function() {
    $("ul.tokyo_sub").fadeToggle(0);
  });
});

$(function() {
  $("#hokkaido_btn a").click(function() {
    $("#hokkaido .content_accordion").slideDown(300);
    $("#hokkaido .content_trigger").addClass('current');
  });
});

$(function() {
  $("#touhoku_btn a").click(function() {
    $("#touhoku .content_accordion").slideDown(300);
    $("#touhoku .content_trigger").addClass('current');
  });
});

$(function() {
  $("#kantou_btn a").click(function() {
    $("#kantou>.content_accordion").slideDown(300);
    $("#kantou>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#chubu_btn a").click(function() {
    $("#chubu .content_accordion").slideDown(300);
    $("#chubu .content_trigger").addClass('current');
  });
});

$(function() {
  $("#kinki_btn a").click(function() {
    $("#kinki .content_accordion").slideDown(300);
    $("#kinki .content_trigger").addClass('current');
  });
});

$(function() {
  $("#chugoku_btn>a").click(function() {
    $("#chugoku>.content_accordion").slideDown(300);
    $("#chugoku>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#shikoku_btn>a").click(function() {
    $("#shikoku>.content_accordion").slideDown(300);
    $("#shikoku>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#kyushu_btn a").click(function() {
    $("#kyushu .content_accordion").slideDown(300);
    $("#kyushu .content_trigger").addClass('current');
  });
});

$(function() {
  $("#okinawa_btn a").click(function() {
    $("#okinawa .content_accordion").slideDown(300);
    $("#okinawa .content_trigger").addClass('current');
  });
});

$(function() {
  $("#tokyo23_btn>a").click(function() {
    $("#tokyo23>.content_accordion").slideDown(300);
    $("#tokyo23>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#tokyotoka_btn>a").click(function() {
    $("#tokyotoka>.content_accordion").slideDown(300);
    $("#tokyotoka>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#kanagawa_btn>a").click(function() {
    $("#kanagawa>.content_accordion").slideDown(300);
    $("#kanagawa>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#saitama_btn>a").click(function() {
    $("#saitama>.content_accordion").slideDown(300);
    $("#saitama>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#chiba_btn>a").click(function() {
    $("#chiba>.content_accordion").slideDown(300);
    $("#chiba>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#ibaraki_btn>a").click(function() {
    $("#ibaraki>.content_accordion").slideDown(300);
    $("#ibaraki>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#gunma_btn>a").click(function() {
    $("#gunma>.content_accordion").slideDown(300);
    $("#gunma>.content_trigger").addClass('current');
  });
});

$(function() {
  $("#tochigi_btn>a").click(function() {
    $("#tochigi>.content_accordion").slideDown(300);
    $("#tochigi>.content_trigger").addClass('current');
  });
});
},{}]},{},[1,2,3,4,5]);
